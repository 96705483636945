<svg width="100%" height="100%" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="20" height="14" rx="0.5" stroke="currentColor" />
    <path
        d="M18.9841 0H2.01899C0.906439 0 0 0.969905 0 2.1628V3.75111H21V2.1628C21 0.971001 20.0967 0 18.9841 0Z"
        fill="currentColor" />
    <path
        d="M2.01899 14.0009C1.52171 14.0009 1 13.5438 1 12.8381L1.00002 5.67773H19.9979V12.8381C19.9979 13.5463 19.477 14.0009 18.982 14.0009H2.01899ZM3.32842 6.99625C2.30679 6.99625 1.58809 7.85986 1.58809 8.78602V9.81855C1.58809 10.7432 2.30331 11.6116 3.32842 11.6116H5.30536C6.33568 11.6116 7.04262 10.7376 7.04262 9.81855V8.78602C7.04262 7.86747 6.33308 6.99625 5.30536 6.99625H3.32842Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2" />
</svg>
